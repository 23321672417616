@import 'src/scss/palette';
@import 'src/scss/functions';

.location {
    .row {
        margin-top: snif-grid(2);
        margin-bottom: snif-grid(2);

        .dscrp {
            word-break: break-word;

            .collapsing {
                transition: none;
            }

            .read-more {
                cursor: pointer;
            }

            .icon {
                color: $deep-gray;
                margin-right: snif-grid(1);
                vertical-align: sub;
            }
        }
    }
}

@media screen and (max-width: $mobile-size) {
    .location {
        padding: 0;

        .row {
            .dscrp {
                margin-bottom: snif-grid(2);

                .icon {
                    margin-right: snif-grid(0.5);
                }
            }
        }
    }
    .location-head {
        padding: 0;
    }
}

.map-container {
    @media screen and (min-width: 0) and (max-width: 767px) {
        .row {
            margin: 0;
        }
        max-width: 100%;
    }
    .map {
        @media screen and (min-width: 1200px) {
            height: 425px;
        }
        @media screen and (min-width: 960px) and (max-width: 1199px) {
            height: 350px;
        }
        @media screen and (min-width: $mobile-size) and (max-width: 959px) {
            height: 325px;
        }
        @media screen and (min-width: 576px) and (max-width: 767px) {
            height: 300px;
            padding: 0;
        }
        @media screen and (min-width: 0) and (max-width: 575px) {
            height: 275px;
            padding: 0;
        }
    }
}
