@import 'src/scss/palette';
@import 'src/scss/functions';

.page-text {
    color: $medium-gray;
}

.mobile-action-button {
    .page-link {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 8px;
        border-left: none !important;
        border-right: none !important;
    }
}

.page-item {
    .page-number {
        letter-spacing: 4px;
    }

    @media screen and (max-width: $mobile-size) {
        a.page-link {
            width: 48px;
            text-align: center;
        }
    }

    .page-link {
        border: 2px solid $fog-gray;
        margin: 0;
    }

    &.disabled {
        .page-link {
            color: $fog-gray;
            border-color: $fog-gray;
        }
    }
}

.p-custom {
    .page-item {
        font-weight: 400;

        &.active {
            font-weight: 500;
        }

        &:not(:first-child) .page-link {
            margin-left: 0;
        }

        &:first-child,
        &:last-child {
            .page-link {
                border: none;
                background: none;
            }
        }
    }

    .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        font-size: 16px;
        line-height: 24px;
        padding: 0;
        border: none;
    }
}

.p-box {
    border: 1px solid black;
}

.p-font-size {
    font-size: 17px;
}

.page-link {
    box-shadow: none !important;
}

@media screen and (min-width: $mobile-size) {
    .mobile-pagination {
        color: $medium-gray;
        padding-bottom: 4px;
    }
}
