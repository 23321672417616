@import 'src/scss/palette';
@import 'src/scss/functions';

.user-member-banner {
    display: flex;
    padding: snif-grid(2);
    margin: 0 snif-grid(2) snif-grid(4);
    background-color: $danger-lite;
    border-radius: 12px;
    cursor: pointer;

    @media #{$min-width-mobile} {
        border-radius: 16px;
    }

    svg {
        margin-right: snif-grid(2);
    }

    > div {
        width: calc(100% - 40px);

        p:nth-child(1) {
            font-weight: 500;

            @media #{$min-width-mobile} {
                font-weight: 600;
            }
        }
    }
}
