@import './variables';
@import './palette';
@import './mixins/typorgaphy';

/* old mixins @deprecated! */

@mixin headline-1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;

    @media #{$min-width-mobile} {
        font-size: 34px;
        line-height: 36px;
    }
}

@mixin headline-2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    @media #{$min-width-mobile} {
        font-size: 28px;
    }
}

@mixin headline-3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;

    @media #{$min-width-mobile} {
        font-size: 24px;
        line-height: 30px;
    }
}

@mixin headline-4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.02px;

    @media #{$min-width-mobile} {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: normal;
    }
}

@mixin copy-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;

    @media #{min-width-mobile} {
        font-size: 22px;
        line-height: 26px;
    }
}

@mixin details {
    font-family: 'Work Sans';
    // color: $medium-gray;

    @media #{$max-width-mobile} {
        font-size: 16px;
        line-height: 19px;
    }

    @media #{$min-width-mobile} {
        font-size: 14px;
        line-height: 16px;
    }
}

@mixin details-1 {
    @include details();
    font-weight: bold;
}

@mixin details-2 {
    @include details();
    font-weight: 600;
}

@mixin details-3 {
    @include details();
    font-weight: normal;
    white-space: pre-line;
}

@mixin details-4 {
    @include details();
    font-weight: 500;
}

@mixin body-copy-base {
    // color: $black;

    @media #{$max-width-mobile} {
        font-size: 16px;
        line-height: 21px;
    }

    @media #{$min-width-mobile} {
        font-size: 16px;
        line-height: 24px;
    }
}

@mixin body-copy {
    @include body-copy-base();
    font-weight: normal;
}

@mixin body-copy-bold {
    @include body-copy-base();
    font-weight: bold;
}

@mixin body-copy-semibold {
    @include body-copy-base();
    font-weight: 600;
}

@mixin body-copy-medium {
    @include body-copy-base();
    font-weight: 500;
}

@mixin link {
    font-weight: 500;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
}

@mixin no-link {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

@mixin dates {
    font-weight: 500;
    color: $light-gray;
    font-size: 14px;
    line-height: 18px;

    @media #{$min-width-mobile} {
        font-size: 12px;
        line-height: 16px;
    }
}

@mixin subtitle {
    font-weight: normal;
    font-style: italic;
    font-size: 18px;
    line-height: 21px;

    @media #{$min-width-mobile} {
        font-size: 16px;
        line-height: 24px;
    }
}

@mixin nav-link {
    font-size: 22px;
    line-height: 26px;
    font-weight: normal;
    color: $deep-gray;

    @media #{$min-width-mobile} {
        font-size: 16px;
        line-height: 19px;
    }
    &__active,
    &__marked {
        font-weight: 600;
    }
}

@mixin p2-regular {
    font-family: 'Work Sans';
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}
