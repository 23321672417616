@import 'src/scss/functions';
@import 'src/scss/palette';

.presents {
    .icon-title {
        margin-right: snif-grid(2);
        color: $deep-gray;
    }

    .icon-info {
        display: none;
        width: 24px;
        height: 24px;
        margin-left: snif-grid(1);
        color: $light-gray;
        cursor: pointer;

        @media #{$min-width-mobile} {
            display: inline;
            padding: 6px;
        }
    }

    .present-chev-r {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        color: $light-gray;

        svg {
            width: 12px;
            height: 12px;
        }

        @media #{$min-width-mobile} {
            display: none;
        }
    }

    .modal-content {
        border: 1px solid $green;
        border-radius: 5px;
    }

    .close {
        font-size: 28px;
    }

    .modal-body,
    .modal-header {
        padding: snif-grid(3);
    }

    .modal-footer {
        border: none;
        .btn {
            width: 180px;
        }
    }

    .row-field {
        padding: 0.5rem 0;

        .present-block {
            margin-bottom: snif-grid(2);

            .field {
                position: relative;
            }

            &:nth-last-child(1),
            &:nth-last-child(2) {
                margin-bottom: snif-grid(1);
            }

            @media #{$max-width-mobile} {
                &:nth-last-child(1) {
                    margin-bottom: snif-grid(1);
                }
                &:nth-last-child(2) {
                    margin-bottom: snif-grid(2);
                }
            }

            @media #{$max-width-mobile} {
                padding-right: 9px;
            }
        }
    }

    p {
        margin: 0;
    }
}

.color-red {
    color: $red;

    &.prvt {
        color: $success-500;
    }
}

.color-green {
    color: $success-500;

    &.prvt {
        color: $red;
    }
}

.color-warn {
    color: $yellow-600;
}

.present-modal {
    .present-feedback {
        margin: snif-grid(3) 0 snif-grid(2);
        padding: snif-grid(2);
        border: 1px solid $green-100;
        border-radius: 10px;
    }

    .rev-comment-item {
        display: flex;
        margin-bottom: snif-grid(3);

        & > div:nth-child(2) {
            width: calc(100% - 32px);
        }
    }

    .rev-no-comment {
        width: calc(100% - 32px);
    }

    .ensure {
        .icon {
            margin-right: snif-grid(1);
            vertical-align: text-bottom;
        }
    }
}
