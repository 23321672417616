@import 'src/scss/functions';
@import 'src/scss/palette';

.host {
    .host-avatar {
        min-width: 72px;
        object-position: center;
        object-fit: cover;
        margin-right: snif-grid(1);
    }

    .host-info-about {
        word-wrap: break-word;
    }

    .host-info {
        margin-top: snif-grid(2);

        .media {
            align-items: center;
            cursor: pointer;

            .media-body {
                height: fit-content;
            }
        }

        .host-name {
            font-weight: 600;
            margin-bottom: snif-grid(0.5);
        }

        .host-join-date {
            color: $deep-gray;
        }

        .host-info {
            margin: 0;
            white-space: pre-wrap;
            font-size: 18px;
        }

        .col-md-12 {
            padding: 0;
        }
    }

    @media #{$min-width-mobile} {
        .account-verified-banner {
            max-width: 392px;
        }
    }
}

@media #{$max-width-mobile} {
    .host {
        padding: 0;
    }
}
