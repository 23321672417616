@import '../../scss/variables';

.carousel__spot-photo-container {
    position: relative;
    padding-top: $photo-height;

    &.p-lcp {
        padding-top: calc($photo-height + 1px);
    }
}

.listing-preview .carousel__spot-photo-container .carousel__photo-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.img.carousel-spot-inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
