@import 'src/scss/variables';
@import 'src/scss/functions';
@import 'src/scss/palette';

.spot-amenities-button {
    margin: snif-grid(1) 0 snif-grid(3);
}

.amenities-list {
    p {
        font-size: 16px;
        margin: 0 0 snif-grid(1);
    }

    .amenities-icon {
        width: 30px;
        height: 30px;
        margin-right: snif-grid(2);
        display: inline-block;
        vertical-align: middle;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.mobile-sub-page {
}

@media screen and (max-width: $mobile-size) {
    .amenities-list {
        p {
            font-size: 16px;
            margin: 0 0 snif-grid(1);
        }
    }
}
