@import '../../scss/font-mixin';
@import '../../scss/mixins';
@import '../../scss/palette';
@import '../../scss/functions';

.sniff-select {
    @include sniff-input;
    padding: 0 !important;

    & &__control {
        width: inherit;
        min-height: 48px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;

        &--is-focused {
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
            color: $dark-gray;
        }
    }

    &:hover {
        .sniff-select__indicator {
            color: $medium-gray;
        }
    }

    &.select-err {
        border-color: $red-200;
    }

    & &__menu {
        z-index: 35;
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        > div {
            padding: 0;
        }
    }

    & &__indicator-separator {
        display: none;
    }

    & &__placeholder {
        margin-left: 0;
    }

    & &__value-container {
        padding-left: 12px;

        &--is-multi {
            padding: 12px;

            .sniff-select__multi-value {
                color: $dark-gray;
                background-color: $fog-gray;
                border-radius: snif-grid(0.5);
            }
        }
    }

    & &__option {
        font-size: 1em;
        transition: all 200ms;

        &--is-focused {
            background-color: $snow-white;
        }

        &:active {
            background-color: $blue-200;
        }

        &--is-selected {
            position: relative;
            background-color: $blue;
            color: $white;

            &::after {
                position: absolute;
                top: 50%;
                right: 12px;
                transform: translateY(-50%);
                content: url('../../assets/svg/SvgCheckmarkOpt.svg');
            }
        }
    }

    & &__indicator {
        color: $light-gray;
    }

    & &__clear-indicator {
        cursor: pointer;
    }
}

.sniff-select-ssr {
    padding: 12px !important;
}
