@import 'src/scss/palette';
@import 'src/scss/functions';

#reviews {
    @media #{$max-width-mobile} {
        overflow: hidden;
    }

    .spot-detail-my-badge {
        width: 100%;
        margin-top: snif-grid(2);

        @media #{$min-width-mobile} {
            max-width: 424px;
        }
    }
}

.comments {
    .rev-head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .star-reviews {
            width: 28px;
            color: $yellow;
            vertical-align: text-top;
            position: relative;
            top: 2px;
        }

        .reviews-count {
            color: $deep-gray;
        }

        .all-reviews {
            cursor: pointer;
        }
    }

    .loading {
        display: inline-block;
        width: 100px;
        height: 100px;
        background: url('../../assets/svg/SvgSpinner.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.all-reviews-desktop {
    max-width: 700px;
}

@media #{$max-width-mobile} {
    .comments {
        padding: 0;

        .rev-head {
            .title-reviews {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .star-reviews {
                    width: 27px;
                    top: 1px;
                }

                .chevron-reviews {
                    width: 8px;
                    height: 14px;
                    color: $soft-gray;
                    margin-right: snif-grid(2);
                }
            }
        }
    }
}
