@import 'src/scss/functions';
@import 'src/scss/palette';
@import 'src/scss/mixins';

.spot-top-badge {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    margin: snif-grid(1);
    padding: 4px 10px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: $green;
    background-color: $bg-green;
    border: none;
    border-radius: 50px;
    z-index: 10;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
        margin-right: snif-grid(1);
    }

    &.rising {
        color: $warning;
        background-color: $warning-100;
    }

    &.new {
        color: $blue-500;
        background-color: $blue-bg;
    }
}
