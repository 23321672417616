@import 'src/scss/palette';
@import 'src/scss/functions';

.sort-reviews {
    position: relative;
    display: flex;
    align-items: center;
    margin: snif-grid(2) 0 snif-grid(5);
    cursor: pointer;

    span {
        font-weight: 500;
        text-decoration: underline;
        margin: 0 snif-grid(1);
    }

    .chevron-sort {
        width: 10px;
        height: 10px;
        color: $deep-gray;
    }

    .sort-reviews-modal {
        position: absolute;
        width: 240px;
        left: 32px;
        top: 32px;
        background: $white;
        border: 1px solid $soft-gray;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        z-index: 50;

        > div {
            position: relative;
            padding: snif-grid(1) snif-grid(1.5);

            &.picked {
                background-color: $snow-white;
            }

            svg {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
                color: $medium-gray;
            }
        }
    }
}
