@import 'src/scss/functions';
@import 'src/scss/palette';

.item-full-expand {
    &_title {
        display: flex;
        justify-content: space-between;
        margin-top: snif-grid(1);

        h4 {
            width: calc(100% - 32px);
            display: block;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &_enclosure {
        display: flex;
        align-items: center;
        color: $medium-gray;
        margin-top: snif-grid(0.5);

        svg {
            width: 16px;
            height: 16px;
            margin-right: snif-grid(1);
        }

        &_dot {
            margin: 0 snif-grid(1);
            width: 2px;
            height: 2px;
            background-color: $medium-gray;
            border-radius: 50%;
        }
    }

    &_desc {
        margin-top: snif-grid(1);
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: $deep-gray;
        display: block;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &_amenities {
        display: flex;
        flex-wrap: wrap;
        margin: snif-grid(2) 0;
        gap: snif-grid(1);

        p {
            padding: 0 snif-grid(1.5);
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            background-color: $snow-white;
            border-radius: 100px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    &_review {
        display: flex;
        color: $medium-gray;

        &_svg {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 24px;
            margin-right: snif-grid(1);

            svg {
                width: 16px;
                height: 16px;
            }
        }

        p {
            width: calc(100% - 24px);
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;

            span {
                color: $green;
                text-decoration: underline;
                text-underline-offset: 4px;
            }
        }
    }

    .spot-type-public {
        font-size: 10px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $green;

        &.public-spot {
            color: $light-gray;
        }
    }
}
