@import 'src/scss/variables';
@import 'src/scss/functions';
@import 'src/scss/palette';

.extras-list {
    .extras-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: snif-grid(3);

        .extras-icon {
            margin-right: snif-grid(2);
        }

        .item-right {
            width: calc(100% - 40px);

            .extra-item-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: snif-grid(1);
            }

            .extra-item-desc {
                margin-bottom: 0;
                word-wrap: break-word;
            }
        }
    }
}

.spot-extras-button {
    margin: 0 0 snif-grid(3);
}

.modal-extras-desktop {
    .modal-dialog {
        max-width: 650px;
    }
}

@media screen and (max-width: $mobile-size) {
    .full-extras {
        position: fixed;
        z-index: 999;
        top: -1px;
        left: 0;
        height: calc(100vh + 1px);
        width: 100%;
        background-color: $white;
        overflow-y: auto;
        padding-bottom: snif-grid(2);
        border-top: 1px solid $soft-gray;

        &.has-banner {
            height: calc(100vh - 49px);
            top: 49px;
        }
    }
}
