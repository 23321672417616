.sniff-skeleton {
    background: linear-gradient(-45deg, #dedede, #f0f0f0, #dedede);
    background-size: 400% 400%;
    animation: skeleton-loading 2s linear infinite;
}

@keyframes skeleton-loading {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
