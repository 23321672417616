@import 'src/scss/palette';
@import 'src/scss/functions';
@import 'src/scss/variables';

.mobile-sub-page {
    position: fixed;
    z-index: 250;
    top: 0;
    left: 0;
    width: 100vw;
    display: none;
    background-color: $white;

    &.show {
        display: block;
    }

    &.hide {
        display: none;
    }

    &__sub-header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 56px;
        background-color: $white;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        z-index: 1;

        .back-ico {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            padding: 21px 16px;

            svg {
                height: 14px;
            }
        }

        .sub-title {
            width: 100%;
            padding: 0 54px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__sub-content {
        padding: snif-grid(3) snif-grid(2) snif-grid(2);
        background-color: $white;
    }
}

@media screen and #{$max-width-mobile} {
    .mobile-sub-page {
        &__sub-content {
            height: calc(100% - 56px);
            overflow-y: auto;
            overflow-y: overlay;
            overflow-x: hidden;
        }
    }
}
