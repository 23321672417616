@import 'src/scss/palette';
@import 'src/scss/functions';
@import 'src/scss/variables';

.spot-avail-desktop {
    max-width: 750px !important;
}

.spot-calendar {
    &.desktop-screen {
        display: flex;

        .desktop-header-block {
            width: 100%;
        }

        .spot-calendar__header {
            .monthly-text {
                position: relative;
                left: 5px;
            }
        }

        .spot-calendar__date_picker {
            margin: 0 -12px;
        }

        .spot-calendar__separator {
            margin: 0 snif-grid(2);
            width: 0;
            height: auto;
            border-left: 1px solid $fog-gray;
        }

        .spot-calendar__availability {
            width: 100%;
            height: 404px;
            overflow-y: auto;
            padding-right: 8px;
            padding-left: 8px;

            .date-picked {
                padding-top: 0;
                top: 0;
            }

            .date-sticky {
                top: 32px;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: snif-grid(2);

        .left {
            display: flex;
            align-items: center;

            .calendar-type {
                color: $medium-gray;
                font-weight: 500;
                padding-bottom: 9px;

                &.active {
                    color: $green;
                    border-bottom: 1px solid $green;
                    padding-bottom: snif-grid(1);
                }

                &:nth-child(2) {
                    margin-left: snif-grid(3);
                }
            }
        }

        .right {
            display: flex;
            align-items: center;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                color: $fog-gray;
                border: 1px solid $fog-gray;
                border-radius: 50%;
                cursor: pointer;

                svg {
                    position: relative;
                    right: 1px;
                }

                &.active {
                    color: $green;
                    border: 1px solid $green;
                }

                &:nth-child(2) {
                    margin-left: snif-grid(1);

                    svg {
                        right: -1px;
                    }
                }
            }
        }
    }

    &__date_picker {
        .weekly {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;

            .w_item {
                position: relative;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: snif-grid(1);
                border-radius: snif-grid(0.5);

                .i_text {
                    color: $medium-gray;
                    font-weight: 600;
                    margin-bottom: snif-grid(0.5);
                }

                .i_num {
                    font-weight: 500;
                }

                &.active {
                    background-color: $green;
                    color: $white;

                    &::after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        transform: rotate(45deg);
                        border: 1px solid $fog-gray;
                        border-bottom: none;
                        border-right: none;
                        position: absolute;
                        background-color: $white;
                        bottom: -22px;
                    }

                    .i_text {
                        color: $white;
                    }

                    &.sold-out {
                        background-color: $snow-white;
                        color: $light-gray;

                        .i_text {
                            color: $light-gray;
                        }
                    }
                }

                &.disabled,
                &.sold-out {
                    color: $soft-gray;

                    .i_text {
                        color: $soft-gray;
                    }

                    .i_num {
                        text-decoration-line: line-through;
                    }
                }
            }
        }

        .monthly {
            .monthly-text {
                text-align: center;
                margin-bottom: snif-grid(2);
            }

            .react-calendar__month-view {
                &__weekdays {
                    text-align: center;

                    abbr {
                        font-size: 12px;
                        font-weight: 600;
                        color: $medium-gray;
                        text-decoration: none;
                    }
                }
                &__days {
                    button {
                        height: 32px;
                        margin-top: 8px;
                        padding-top: 0;
                        background-color: $white;
                        outline: none !important;
                        border: none !important;

                        &:disabled {
                            abbr {
                                color: $soft-gray;
                                text-decoration-line: line-through;
                            }
                        }

                        &.react-calendar__tile--active {
                            abbr {
                                background-color: $green;
                                color: $white;
                                border-radius: snif-grid(0.5);
                                width: 32px;
                                height: 32px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 0 auto;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &__separator {
        margin: snif-grid(2) snif-grid(-2) 0;
        width: 100vw;
        height: 0;
        border-top: 1px solid $fog-gray;
    }

    &__availability {
        .date-picked {
            padding: snif-grid(3) 0 snif-grid(1);
            position: sticky;
            top: snif-grid(0);
            background-color: $white;

            @media screen and #{$max-width-mobile} {
                top: snif-grid(-3);
            }
        }

        .date-sticky {
            position: sticky;
            top: snif-grid(7);
            background-color: $white;

            @media screen and #{$max-width-mobile} {
                top: snif-grid(4);
            }
        }

        .settings {
            margin-bottom: snif-grid(3);
            font-size: 16px;

            @media screen and (max-width: 350px) {
                font-size: 14px;
            }

            .settings-toggle {
                font-weight: 600;
                color: $green;
                cursor: pointer;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-left: snif-grid(1);
                }
            }
        }

        .unaval-tips {
            display: flex;
            align-items: flex-start;
            padding: snif-grid(2);
            border: 1px solid $fog-gray;
            border-radius: snif-grid(1);

            .tips-ico {
                width: 24px;
                height: 24px;
                margin-right: snif-grid(2);
                color: #caa13d;
            }
        }

        .my-fav-spots {
            display: block;
            position: absolute;
            bottom: 16px;
            left: 0;
            width: 100%;
            color: $green;
            text-align: center;
            cursor: pointer;

            &.monthly {
                position: relative;
                bottom: 0;
                margin-top: snif-grid(10);
            }
        }

        .time-picker {
            display: flex;
            flex-wrap: nowrap;
            padding-bottom: snif-grid(3);

            .day-button {
                flex: 1;
                padding: snif-grid(1.5);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: snif-grid(1);
                color: $deep-gray;
                cursor: pointer;

                &:nth-child(2) {
                    margin: 0 snif-grid(2);
                }

                svg {
                    margin-bottom: snif-grid(0.5);
                    color: $medium-gray;
                }

                &.day-active {
                    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
                    color: $dark-gray;

                    svg {
                        &.morning {
                            color: #cfc814;
                        }

                        &.afternoon {
                            color: #ef9935;
                        }

                        &.evening {
                            color: $blue;
                        }
                    }
                }

                &.day-empty {
                    color: $light-gray;
                    text-decoration-line: line-through;

                    svg {
                        color: $soft-gray;
                    }

                    &.day-active {
                        color: $medium-gray;

                        svg {
                            color: $light-gray !important;
                        }
                    }
                }
            }
        }

        .time-list {
            .time-book {
                color: $deep-gray;
                font-weight: 500;
                margin-bottom: snif-grid(1);

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                .member-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    float: right;
                    height: 24px;

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .empty-list {
                text-align: center;
                margin-bottom: 0;
            }
        }
    }

    &__today-dot {
        position: absolute;
        bottom: 3px;
        left: 50%;
        transform: translateX(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;

        &.green {
            background-color: $green;
        }

        &.white {
            background-color: $white;
        }
    }
}

.req-member-modal {
    .req-member-title {
        display: flex;
        margin-bottom: snif-grid(3);

        .member-title-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: snif-grid(1);

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .member-title-text {
            width: calc(100% - 40px);
        }
    }

    .req-button {
        background-color: $yellow !important;
        color: $dark-gray !important;
        margin-bottom: snif-grid(2);
    }

    .no-button {
        font-size: 14px;
        line-height: 24px;
        color: $deep-gray !important;
        background-color: $white !important;
        border: $white !important;
    }

    @media screen and #{$min-width-mobile} {
        max-width: 440px;
        margin-top: snif-grid(6);
    }
}

.sent-member-modal {
    .sent-member-title {
        display: flex;
        margin-bottom: snif-grid(3);

        .member-title-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: snif-grid(1);
            color: $green;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .member-title-text {
            width: calc(100% - 40px);
        }
    }

    @media screen and #{$min-width-mobile} {
        max-width: 440px;
        margin-top: snif-grid(6);
    }
}

.calendar-setting-modal {
    @media #{$min-width-mobile} {
        max-width: 440px !important;
    }
}
