@import 'src/scss/palette';
@import 'src/scss/variables';
@import 'src/scss/functions';

.listings {
    position: relative;

    .row {
        margin: 0;
        &.indent-many {
            justify-content: space-evenly;
            justify-content: space-around;
        }
        &.indent-one {
            justify-content: flex-start;
        }
    }

    .other-spots-title {
        width: 100%;
        margin-top: snif-grid(1);
        padding: snif-grid(2) 0 snif-grid(1);
        border-top: 1px solid $fog-gray;

        @media screen and #{$min-width-mobile} {
            padding-left: snif-grid(1);
        }
    }

    .preloader {
        &_absolute {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            z-index: 5;
        }
    }
}

.listing-search-tile {
    width: 33.33%;
    padding: 0 snif-grid(1);
    margin-bottom: snif-grid(2);

    @media screen and #{$max-width-mobile} {
        width: 100%;
        padding: 0;
        margin: 1rem 0 snif-grid(4) 0;
    }

    .search-tile-content {
        display: flex;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 8px;

        .search-tile-left {
            width: 8px;
            background: $success-500;
            border-radius: 8px 0px 0px 8px;
        }

        .search-tile-right {
            padding: snif-grid(2) snif-grid(2) snif-grid(3);

            .tile-right-box {
                margin-bottom: snif-grid(1);

                span {
                    display: inline-block;
                    padding: snif-grid(1) snif-grid(2);
                    margin: 0 snif-grid(1) snif-grid(2) 0;
                    color: $deep-gray;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    border: 1px solid $fog-gray;
                    border-radius: 100px;
                    cursor: pointer;

                    &.view-more {
                        color: $medium-gray;
                        text-decoration: underline;
                        border: none;
                        padding: 4px;
                    }

                    &.active {
                        color: $green;
                        border-color: $green;
                    }
                }
            }
        }
    }
}

.in-row {
    overflow-x: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 4px;
    }
}

.spot-listing {
    width: 100%;
}

@media (max-width: $mobile-size) {
    .mobile-listings-container {
        .in-row {
            overflow: scroll;
        }

        .listings {
            padding: 0;
            margin: 0 auto 0 auto;
            height: 100%;

            .listing-preview {
                .item-title {
                    margin-top: 8px !important;
                }

                .item-detail {
                    span svg {
                        margin-right: 6px;
                    }
                }

                .info {
                    margin: 8px 0;

                    &.first {
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media (min-width: $mobile-size) {
    .mobile-listings-container .listing-preview .item-detail span svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        vertical-align: text-bottom;
    }
}
