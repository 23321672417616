@import 'src/scss/palette';
@import 'src/scss/functions';

.spot-breadcrumbs {
    padding-top: snif-grid(2);

    ol {
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        display: flex;
        align-items: center;
        list-style-type: none;
        margin-bottom: 0;
        margin-right: 16px;
        overflow-x: hidden;

        li,
        a {
            display: flex;
            align-items: center;
            max-width: 104px;
            overflow-x: hidden;
            text-overflow: ellipsis;

            &.bc-spot-title-wrapper {
                max-width: 152px;
            }

            @media screen and #{$min-width-mobile} {
                max-width: unset;

                &.bc-spot-title-wrapper {
                    max-width: 350px;
                }
            }
        }
    }

    &__name {
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: $medium-gray;
        text-transform: capitalize;
        white-space: nowrap;

        &.link {
            text-decoration: underline;
        }

        &.bc-spot-title {
            white-space: nowrap;
        }
    }

    &__icon-right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8px;
        height: 8px;
        margin: 0 4px;
        color: $light-gray;

        svg {
            width: 6px;
            height: 6px;
        }
    }

    @media screen and #{$max-width-mobile} {
        height: 48px;
        overflow: hidden;

        ol {
            height: 48px;
            overflow-x: overlay;
            padding-bottom: 12px;
            box-sizing: content-box;

            li,
            a {
                max-width: unset;
                overflow-x: unset;
                text-overflow: unset;
            }

            &.bc-spot-title-wrapper {
                max-width: unset;
            }
        }
    }

    @media screen and #{$min-width-mobile} {
        padding-top: 0;

        ol {
            padding-inline-start: 0;
            display: flex;
            align-items: center;
            list-style-type: none;
            margin-bottom: snif-grid(2);

            li,
            a {
                display: flex;
                align-items: center;
            }
        }
    }
}
