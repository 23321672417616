@import './variables';
@import './font-mixin';

@each $weight, $values in $snif-font-weights {
    .snif-#{$weight} {
        @include font-weight($weight);
    }
}

@each $weight, $params in $snif-font-weights {
    .snif-#{$weight}-pc {
        @media screen and #{$min-width-mobile} {
            @include font-weight($weight);
        }
    }
}

@each $variant, $params in $snif-typographies {
    .snif-#{$variant} {
        @include snif-typography($variant);
    }
}

@each $variant, $params in $snif-typographies {
    .snif-#{$variant}-pc {
        @media screen and #{$min-width-mobile} {
            @include snif-typography($variant);
        }
    }
}

.headline-1 {
    @include headline-1;
}
.headline-2 {
    @include headline-2;
}
.headline-3 {
    @include headline-3;
}

.headline-4 {
    @include headline-4;
}

/* old classes */

.details-2 {
    @include details-2;
}

.details-3 {
    @include details-3;
}

.details-4 {
    @include details-4;
}

.copy-title {
    @include copy-title;
}

body,
.body-copy {
    @include body-copy;
}

.body-copy-bold {
    @include body-copy-bold;
}

.body-copy-semibold {
    @include body-copy-semibold;
}

.body-copy-medium {
    @include body-copy-medium;
}

.link {
    @include link;
}

.no-link {
    @include no-link;
}

.dates {
    @include dates;
}

.subtitle {
    @include subtitle;
}

.nav-link {
    @include nav-link;
}
