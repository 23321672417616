@import 'src/scss/palette';
@import 'src/scss/functions';

.request-more-photos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: snif-grid(3) 0;
    background-color: $success-100;

    > p {
        height: 48px;

        &.no-height {
            height: auto;
        }
    }

    &__text-sent {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: $success-500;

        svg {
            margin-right: snif-grid(1.5);
        }
    }
}
