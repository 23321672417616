@import 'src/scss/palette';
@import 'src/scss/functions';

.designed-for-private-comp {
    display: flex;
    padding: snif-grid(2);
    background-color: $blue-bg;
    border-radius: 6px;

    &__icon {
        width: 24px;
        height: 24px;
        margin-right: snif-grid(2);
        color: $blue;
    }

    &__content {
        width: calc(100% - 40px);
    }

    &__title {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        color: $blue;

        @media #{$min-width-mobile} {
            font-size: 16px;
        }
    }

    &__subtitle {
        margin-top: snif-grid(0.5);
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: $deep-gray;

        @media #{$min-width-mobile} {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &.private-banner {
        @media #{$min-width-mobile} {
            display: block;
            padding: snif-grid(3);
            text-align: center;
            border-radius: 0;

            .designed-for-private-comp__content {
                width: unset;
                display: inline;

                div {
                    display: inline;
                }
            }

            .designed-for-private-comp__subtitle {
                margin-top: 0;
            }
        }
    }
}
