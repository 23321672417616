@import 'src/scss/palette';
@import 'src/scss/functions';

.map-spot-callout-cont {
    position: relative;
    width: 0;
    height: 0;

    &.call-top {
        .map-spot-callout {
            top: 0;
        }
    }

    &.call-bottom {
        .map-spot-callout {
            bottom: 0;
        }
    }

    &.call-left {
        .map-spot-callout {
            left: 0;
        }
    }

    &.call-right {
        .map-spot-callout {
            right: 0;
        }
    }
}

.map-spot-callout {
    position: absolute;
    width: 250px;
    max-height: 700px;
    padding: snif-grid(1) snif-grid(1) snif-grid(3);
    background-color: $white;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    cursor: pointer;

    &__imgDiv {
        img {
            width: 234px;
            height: 150px;
            object-fit: cover;
            object-position: center;
            border-radius: 4px;
        }
    }

    .spot-type-public {
        font-size: 10px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: snif-grid(1.5);
        color: $light-gray;
    }

    h3 {
        color: $green;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        margin-top: snif-grid(0.5);
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__detail,
    &__price,
    &__rating {
        margin-top: snif-grid(0.25);
        font-size: 14px;
        line-height: 24px;
        color: $deep-gray;
    }

    &__rating {
        display: flex;
        align-items: center;

        span {
            margin-left: snif-grid(0.5);
        }
    }
}

.sniff-listings-anno {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    height: 32px;
    padding: snif-grid(0.5) snif-grid(1.5);
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    background-color: $white;
    border-radius: 100px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &_span-svg {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;

        svg {
            width: 14px;
            height: 14px;
        }
    }

    &.no-size {
        padding: 0 !important;

        .sniff-listings-anno_span-svg {
            width: 32px;
            height: 32px;
        }
    }

    &.active {
        color: $white;
        background-color: $green;
    }
}

@keyframes breathing {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.sniff-listings-anno-my-loc {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: $white;
    border-radius: 50%;

    > div {
        width: 16px;
        height: 16px;
        background-color: #0074f1;
        border-radius: 50%;
        animation: breathing 2s ease 0s infinite normal forwards;
    }
}
