@import 'src/scss/palette';

.preloader {
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
        width: 48px;
        height: 48px;
        color: $green;
    }

    &_full {
        flex: 1;
    }

    &_background {
        background-color: rgba($white, 0.75);
    }

    &_full-window {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 100;
    }

    &_absolute {
        position: absolute;
        top: 0;
        left: 0;
        left: 0;
        right: 0;
    }
}
