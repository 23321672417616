@import 'src/scss/functions';
@import 'src/scss/palette';

.fixed-bottom {
    background-color: $white;
    z-index: 120;

    .panel-wrapper {
        margin: 0;
        padding: snif-grid(2) snif-grid(3);

        .panel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .spot-name {
                color: $deep-gray;
                margin-bottom: snif-grid(0.5);
            }

            .raiting-row {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                font-size: 18px;

                .comments-count {
                    color: $deep-gray;
                }
            }

            .price {
                margin: 0;
                color: $deep-gray;
            }

            .price-mobile {
                display: none;
            }

            .right {
                border-left: 1px solid $soft-gray;
                padding-left: snif-grid(2);

                .reservation-button {
                    position: relative;
                    margin-left: snif-grid(2);
                    padding: 12px 32px;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-size) {
    .fixed-bottom {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);

        .panel-wrapper {
            padding: snif-grid(2);

            .panel {
                .left {
                    .price-mobile {
                        margin-bottom: snif-grid(0.5);
                        display: block;

                        .price {
                            color: $deep-gray;
                        }
                    }

                    .spot-name {
                        display: none;
                    }

                    .raiting-row {
                        font-size: 14px;

                        .star-icon {
                            width: 16px;
                            height: 16px;
                            margin-right: snif-grid(1);
                        }

                        .ratings-count {
                            margin-right: snif-grid(1);
                        }
                    }
                }

                .right {
                    border: none;
                    padding-left: 0;

                    .reservation-button {
                        margin-left: 0;
                        padding: 12px 24px;
                        white-space: nowrap;
                        font-size: 16px;
                    }

                    .price {
                        display: none;
                    }
                }
            }
        }
    }
}
