@import 'src/scss/palette';
@import 'src/scss/functions';

.rules {
    .book-policy {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            width: 8px;
            height: 14px;
            color: $soft-gray;
            margin-right: snif-grid(2);
        }
    }

    .desktop-rules {
        position: relative;
        cursor: pointer;
        font-weight: 500;
    }
}

.rule-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .d-flex {
        width: 100%;
        margin-bottom: snif-grid(2);
        padding-right: 0;

        .rule-list-item {
            width: calc(100% - 40px);

            &.break-word {
                word-wrap: break-word;
            }
        }
    }

    @media screen and #{$min-width-mobile} {
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;

        .d-flex {
            width: 50%;
            padding-right: snif-grid(2);
        }
    }

    tr {
        vertical-align: top;
        width: 100%;

        td {
            padding-bottom: snif-grid(2);
        }
    }

    .rule-icon {
        margin-right: snif-grid(2);

        &.avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
        }
    }

    .general-rule-icon {
        width: 16px;
        height: 16px;
        margin-right: snif-grid(1);
    }

    .rule-list-item {
        font-size: 16px;

        .read-more {
            cursor: pointer;
        }

        > div {
            font-weight: 600;
            margin-bottom: snif-grid(1);
        }

        &.general {
            padding-bottom: snif-grid(3);
        }
    }
}
