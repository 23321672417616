@import 'src/scss/palette';
@import 'src/scss/functions';
@import './skeleton.scss';

.skeleton-spot-item {
    &__img {
        width: 100%;
        padding-top: $photo-height;
        border-radius: 10px;
        @extend .sniff-skeleton;
    }

    &__text {
        width: 100%;
        height: 24px;
        margin-top: snif-grid(1);
        border-radius: 4px;
        @extend .sniff-skeleton;
    }
}
