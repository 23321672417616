@import 'src/scss/palette';
@import 'src/scss/functions';

.account-verified-banner {
    padding: snif-grid(2);
    border-radius: 12px;
    background-color: $bg-green;
    margin-bottom: snif-grid(4);

    &__title {
        color: $success-700;
    }

    &__verify-item {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        margin-top: snif-grid(2);

        svg {
            width: 16px;
            height: 16px;
            margin-right: snif-grid(1.5);
        }

        span {
            width: calc(100% - 28px);
        }
    }
}
