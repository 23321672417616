@import 'src/scss/palette';
@import 'src/scss/mixins';
@import 'src/scss/typography';
@import 'src/scss/functions';

/* Listings preview */
.listing-preview {
    position: relative;
    padding: 1rem 0rem 0 1rem;
    font-weight: 500;
    font-size: $h6-size;

    a.link-spot {
        display: block;
    }

    .gray {
        color: $medium-gray;
    }

    .carousel {
        position: relative;
        height: 100%;

        .carousel-item {
            position: relative;
            padding-top: $photo-height;
        }

        .carousel-spot-inner.img {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 10px;
        }

        .car-inner-photo-req {
            border-radius: 10px;
            overflow: hidden;
        }

        .carousel-banner-is-member {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 10px;
            background-color: $danger-lite;
            border-radius: 0 0 8px 8px;
            z-index: 10;

            svg {
                width: 20px;
                height: 20px;
                margin-right: snif-grid(1);
            }
        }
    }

    a {
        text-decoration: inherit !important;
        font-size: 2rem;
        color: inherit !important;
    }

    &.home-page .spot-details {
        margin-bottom: snif-grid(7);

        &.spot-membership {
            margin-bottom: 0;
        }

        .preview-book-btn {
            position: absolute;
            width: calc(100% - 16px);
            bottom: 0;
        }

        .learn-more-btn {
            margin-top: snif-grid(2);
            color: $dark-gray !important;
            background-color: $yellow !important;
        }

        @media screen and (max-width: 768px) {
            .preview-book-btn {
                width: 100%;
            }
        }
    }

    .item-title {
        margin-top: snif-grid(1);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.listing-page {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &.offer-membership {
            position: relative;

            span {
                width: calc(100% - 32px);
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            svg {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        &.listings-homepage {
            &.offer-membership {
                span {
                    display: block;
                    -webkit-line-clamp: unset;
                    -webkit-box-orient: unset;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }

    .item-title-sub {
        font-size: 12px;
        line-height: 16px;
        color: $deep-gray;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .item-detail {
        flex-wrap: wrap;

        .spot-enc-tag,
        .spot-size-tag {
            display: flex;
            align-items: center;
            padding: 4px 6px;
            background-color: $snow-white;
            border-radius: 100px;

            span {
                white-space: nowrap;
            }

            svg {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }

            @media #{$min-width-mobile} and (max-width: 1450px) {
                padding: 4px 8px;

                svg {
                    display: none;
                }
            }
        }

        .spot-rating-tag {
            display: flex;
            align-items: center;

            .star-rating {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .item-detail-price {
        display: flex;
        justify-content: space-between;
        margin-top: snif-grid(1);

        .item-detail-price-left {
            white-space: nowrap;
        }

        .item-detail-price-right {
            display: flex;
            align-items: center;
            margin-left: snif-grid(1.5);
            max-width: calc(100% - 142px);

            @media #{$min-width-mobile} {
                position: relative;
                top: 2px;
            }

            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .info {
        font-size: 16px !important;
        line-height: 18px;

        &.first {
            display: flex;
            font-weight: 400;
            padding: snif-grid(0.5) 0;
        }

        &.second {
            margin-bottom: 1.5rem;

            .price {
                font-weight: 400;
                padding-bottom: snif-grid(0.5);

                span {
                    font-weight: 700;
                }
            }
        }

        .size::before {
            content: '•';
            padding: 0 0.5rem;
        }

        .enc-type {
            margin: 0;
        }
    }

    &.listing-test {
        position: relative;

        @media screen and (max-width: 768px) {
            &:nth-last-child(1) {
                border-bottom: none !important;
            }
        }

        @media screen and (min-width: 768px) {
            padding-bottom: 56px;
        }

        .listing-preview-book-btn {
            margin: 12px 0 8px;
            font-weight: 500;
            color: $green;
            background-color: $white;
            border-color: $green;

            @media screen and (min-width: 768px) {
                position: absolute;
                bottom: 20px;
                left: 8px;
                width: calc(100% - 16px);
                margin-bottom: 0;
            }
        }
    }
}

.unavailable {
    @include snif-typography('m2');
    @include font-weight('semibold');
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    color: $fog-gray;
    background: radial-gradient(48.35% 110.6% at 50% 50%, rgba($medium-gray, 0.9) 0%, rgba($medium-gray, 0.75) 98.96%);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Map preview */
.map-preview {
    width: 225px;
    overflow: hidden;
    background-color: $white;
    font-family: 'Work Sans';

    a {
        text-decoration: inherit !important;
        font-size: 2rem;
        color: inherit !important;
    }

    .carousel {
        height: 160px;
        margin-bottom: 0;

        .carousel-spot-inner.img {
            border-radius: snif-grid(1);
        }

        .instant-book {
            position: absolute;
            width: 20px;
            height: 20px;
            right: 5px;
            top: 10px;
            z-index: 9;
        }
    }

    .item-title {
        padding-top: 4px;
        color: $green;
        font-size: 18px;
        font-weight: 500;
    }

    .info {
        color: $dark-gray;
        text-decoration: none;
        font-size: 16px;

        &.first {
            display: flex;
            padding-top: 4px;
        }

        &.second {
            padding-top: 4px;

            .price {
                padding-bottom: 4px;

                span {
                    font-weight: 600;
                }
            }
            .raiting-value {
                margin-left: 6px;
            }
        }

        .size::before {
            content: '•';
            padding: 0 0.5rem;
        }

        .enc-type {
            margin: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    /* Listings preview */
    .listing-preview {
        padding: 0;
        width: 100%;
        margin: snif-grid(1) 0 0 0;

        &.home-page {
            max-width: calc(100% - 24px);
            min-width: calc(100% - 24px);
            margin-right: snif-grid(1.5);
        }

        a {
            font-size: $h6-size;
        }

        .info {
            font-size: $h6-size;
            margin: 1rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    /* Map preview */
    .map-preview {
        .preview-content {
            padding: 0;
            padding-top: 5px;
        }
    }
}

@media screen and (max-width: 325px) {
    /* Listings preview */
    .listing-preview {
        .item-detail {
            span {
                font-size: 12px !important;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .listing-preview {
        width: 25%;
        padding: 0 snif-grid(1);
    }
}
