@import 'src/scss/variables';
@import 'src/scss/palette';

.availability {
    display: none;
    .link {
        color: $green;
        cursor: pointer;
    }
    .link:hover {
        text-decoration: underline;
    }
}

@media screen and (max-width: $mobile-size) {
    .availability {
        display: block;
    }
}
