@import 'src/scss/palette';

.empty-spots {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    text-align: center;

    &__location {
        color: $dark-gray;
        margin: 8px 0 16px;
    }

    &__head {
        margin-bottom: 16px;
    }

    &__message {
        margin-bottom: 40px;
    }

    &__button {
        cursor: pointer;
        margin-bottom: 40px;
    }
}
